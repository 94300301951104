* {
	margin: 0;
	padding: 0;
	font-family: Calibri;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	--color-primary: #deff6b;
	--color-primary-hover: #e7ff8f;
	--color-primary-active: #f1ffbf;
	--color-container: #f6ffed;
	--color-footer: #404040;
}

.fwc,
.fwhc {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.fwhc {
	justify-content: center;
}

.App {
	height: 100vh;
}

.layout {
	flex: 1 0 auto;
	padding: 10px 0;
}
.center {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 10px;
}

.width {
	width: 900px;
}

header {
	width: 100%;
	padding: 10px 0;
	border-bottom: 2px solid gray;
}
.logo {
	color: var(--color-primary);
	font-size: 40px;
	font-weight: bold;
	text-decoration: none;
}

footer {
	width: 100%;
	padding: 20px 0;
	background-color: var(--color-footer);
	color: white;
}

.button {
	background-color: var(--color-primary);
	color: white;
	cursor: pointer;
	text-decoration: none;
	transition: 100ms;
}
.button:hover {
	background-color: var(--color-primary-hover);
	box-shadow: 0 0 2px var(--color-primary-hover);
}
.button:active {
	background-color: var(--color-primary-active);
	box-shadow: 0 0 5px var(--color-primary-active);
}

.main .button {
	margin: 10px 0;
	font-size: 40px;
	padding: 40px;
	border-radius: 20px;
}
.main .button .text {
	margin-left: 40px;
}

.list .item {
	cursor: pointer;
}
.list .item:hover {
	background-color: var(--color-primary-hover);
}
.list .item:active {
	background-color: var(--color-primary-active);
}

.sbutton {
	display: block;
	width: 100%;
}

.auth .form {
	max-width: 300px;
}

.messagebox {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	height: 100%;
}
.messagebox .text {
	flex-grow: 1;
}

.orderslist .sort {
	text-align: right;
}
.orderslist .sort * {
	text-align: left;
}

@media (max-width: 1000px) {
	.width {
		width: 90%;
	}
}

@media (max-width: 500px) {
	.main .button {
		font-size: 20px;
		padding: 20px;
		border-radius: 10px;
	}
	.main .button .text {
		margin-left: 20px;
	}
}
